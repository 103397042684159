import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'baseui/skeleton'
import { Avatar as Av } from 'baseui/avatar'
import { useStyletron } from 'baseui'

import { useHorseUser } from '@hooks/Horse/useHorseUser'

const Avatar = ({ id }) => {
  const { isLoading, data = {} } = useHorseUser(id)
  const [css, theme] = useStyletron()

  if (isLoading)
    return (
      <Skeleton
        height="50px"
        width="50px"
        animation
        overrides={{
          Root: {
            style: {
              borderRadius: '50%'
            }
          }
        }}
      />
    )

  const { user = {} } = data
  const { avatar, name } = user

  return (
    <div
      className={css({
        paddingTop: theme.sizing.scale100,
        paddingBottom: theme.sizing.scale100
      })}
    >
      <Av src={avatar} name={name} size="scale1200" />
    </div>
  )
}

Avatar.propTypes = {
  id: PropTypes.number.isRequired
}

export default Avatar
