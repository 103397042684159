import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'

export const HorizontalRule = ({ pt, pb }) => {
  const [css] = useStyletron()

  return (
    <div
      className={css({
        paddingTop: `${pt}px`,
        paddingBottom: `${pb}px`,
      })}
    >
      <hr />
    </div>
  )
}

HorizontalRule.propTypes = {
  pt: PropTypes.number,
  pb: PropTypes.number,
}

HorizontalRule.defaultProps = {
  pt: 10,
  pb: 10,
}
