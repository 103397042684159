import { useQuery } from 'react-query'
import axios, { AxiosResponse, AxiosError } from 'axios'
import toInteger from 'lodash/toInteger'

import { Image } from '@source/types/Image'

interface Values {
  horseId: string
}

function getImages(horseId: Values): Promise<{ images: Image[] }> {
  return axios
    .get<Image, AxiosResponse<{ images: Image[] }>, Values>(`/api/horses/${horseId}/images`)
    .then((response) => response.data)
}

export const useHorseImages = (horseId) => {
  return useQuery<{ images: Image[] }, AxiosError>(['horse', toInteger(horseId), 'images'], async () =>
    getImages(horseId)
  )
}
