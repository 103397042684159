import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'

export const Flex = ({ overrides, children }) => {
  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        ...overrides
      })}
    >
      {children}
    </div>
  )
}

Flex.propTypes = {
  overrides: PropTypes.object,
  children: PropTypes.node.isRequired
}

Flex.defaultProps = {
  overrides: {}
}

export const FlexItem = ({ overrides, shrink, children }) => {
  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        paddingTop: theme.sizing.scale100,
        paddingBottom: theme.sizing.scale100,
        paddingLeft: theme.sizing.scale100,
        paddingRight: theme.sizing.scale100,
        flex: shrink ? '0 0 auto' : '1 1 100%',
        ...overrides
      })}
    >
      {children}
    </div>
  )
}

FlexItem.propTypes = {
  overrides: PropTypes.object,
  shrink: PropTypes.bool,
  children: PropTypes.node.isRequired
}

FlexItem.defaultProps = {
  overrides: {},
  shrink: false
}
