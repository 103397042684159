/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { H1 as One, H2 as Two, H3 as Three, H4 as Four, H5 as Five, H6 as Six } from 'baseui/typography'
import { useStyletron } from 'baseui'

export const H1 = ({ children, ...props }) => {
  const [css] = useStyletron()

  return (
    <One marginTop={['15px']} marginBottom={['15px']} {...props}>
      <span
        className={css({
          fontFamily: 'Poppins',
          fontWeight: 400,
          letterSpacing: '-2px'
        })}
      >
        {children}
      </span>
    </One>
  )
}

H1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

export const H2 = ({ children, ...props }) => {
  const [css] = useStyletron()

  return (
    <Two marginTop={['15px']} marginBottom={['15px']} {...props}>
      <span
        className={css({
          fontFamily: 'Poppins',
          fontWeight: 400,
          letterSpacing: '-2px'
        })}
      >
        {children}
      </span>
    </Two>
  )
}

H2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

export const H3 = ({ children, ...props }) => {
  const [css] = useStyletron()

  return (
    <Three marginTop={['15px']} marginBottom={['15px']} {...props}>
      <span
        className={css({
          fontFamily: 'Poppins',
          fontWeight: 400,
          letterSpacing: '-2px'
        })}
      >
        {children}
      </span>
    </Three>
  )
}

H3.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

export const H4 = ({ children, ...props }) => {
  const [css] = useStyletron()

  return (
    <Four marginTop={['15px']} marginBottom={['15px']} {...props}>
      <span
        className={css({
          fontFamily: 'Poppins',
          fontWeight: 400,
          letterSpacing: '-2px'
        })}
      >
        {children}
      </span>
    </Four>
  )
}

H4.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

export const H5 = ({ children, ...props }) => {
  const [css] = useStyletron()

  return (
    <Five marginTop={['15px']} marginBottom={['15px']} {...props}>
      <span
        className={css({
          fontFamily: 'Poppins',
          fontWeight: 400,
          letterSpacing: '-2px'
        })}
      >
        {children}
      </span>
    </Five>
  )
}

H5.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

export const H6 = ({ children, ...props }) => {
  const [css] = useStyletron()

  return (
    <Six marginTop={['15px']} marginBottom={['15px']} {...props}>
      <span
        className={css({
          fontFamily: 'Poppins',
          fontWeight: 400,
          letterSpacing: '-1px'
        })}
      >
        {children}
      </span>
    </Six>
  )
}

H6.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

export default H1
