import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'baseui/skeleton'
import { Paragraph4 } from 'baseui/typography'
import { useStyletron } from 'baseui'

import { H5 as Heading } from '@global/Heading'
import { useHorseUser } from '@hooks/Horse/useHorseUser'
import { timeAgo } from '@src/lib/timeAgo'

const Name = ({ id }) => {
  const { isLoading, isError, error, isSuccess, data = {} } = useHorseUser(id)
  const [css] = useStyletron()

  if (isLoading) return <Skeleton height="32px" width="150px" animation />

  const { user = {} } = data
  const { name, createdAt } = user

  return (
    <div>
      <Heading marginTop={0} marginBottom={0}>
        {name}
      </Heading>
      <Paragraph4 marginTop={0} marginBottom={0}>
        Joined {timeAgo(createdAt)}
      </Paragraph4>
    </div>
  )
}

Name.propTypes = {
  id: PropTypes.number.isRequired
}

export default Name
