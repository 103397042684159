import React from 'react'
import PropTypes from 'prop-types'
import { StyledTitle } from 'baseui/card'
import { Skeleton } from 'baseui/skeleton'
import { useStyletron } from 'baseui'

import { useHorseTitle } from '@hooks/Horse/useHorseTitle'

const Title = ({ id }) => {
  const { isLoading, isError, error, isSuccess, data } = useHorseTitle(id)
  const [css, theme] = useStyletron()

  if (isLoading)
    return (
      <div
        className={css({
          marginTop: theme.sizing.scale100,
          marginBottom: theme.sizing.scale500
        })}
      >
        <Skeleton height="32px" width="150px" animate />
      </div>
    )

  return <StyledTitle>{data?.title}</StyledTitle>
}

Title.propTypes = {
  id: PropTypes.number.isRequired
}

export default Title
