import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Image from 'next/image'

const Img = ({ src, width, height, alt }) => {
  const [css, theme] = useStyletron()

  return (
    <section
      className={css({
        borderTopLeftRadius: theme.borders.radius200,
        borderTopRightRadius: theme.borders.radius200,
        borderBottomLeftRadius: theme.borders.radius200,
        borderBottomRightRadius: theme.borders.radius200,
        backgroundColor: theme.colors.backgroundPrimary,
        overflow: 'hidden',
        display: 'block',
        textAlign: 'center'
      })}
    >
      <Image src={src} width={width} height={height} alt={alt} layout="responsive" />
    </section>
  )
}

Img.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string
}

Img.defaultProps = {
  alt: ''
}

export default Img
