import isArray from 'lodash/isArray'
import imageAspectRatio from 'image-aspect-ratio'

export const getImagesWithoutPrimary = (images) => {
  return isArray(images) ? images.filter((i) => !i.primary) : []
}

// always return an object
export const getPrimaryImage = (images) => {
  return isArray(images) && images.length > 0
    ? images.filter((i) => i.primary).length > 0
      ? images.find((i) => i.primary)
      : images[0]
    : {}
}

export const countPrimaryImage = (images) => {
  return isArray(images) ? images.filter((i) => i.primary).length : 0
}

export const reduceAspectRatio = (images) => {
  return images.map((i) => {
    return { ...i, ...imageAspectRatio.calculate(i.width, i.height, 1100, 500) }
  })
}
