import React from 'react'
import PropTypes from 'prop-types'
import { StyledHeaderImage } from 'baseui/card'
import { Skeleton } from 'baseui/skeleton'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import isNull from 'lodash/isNull'

import { useHorseImages } from '@hooks/Horse/useHorseImages'
import { getPrimaryImage } from '@src/lib/images'
import GlobalImage from '@global/Image'

const Image = ({ id }) => {
  const { isLoading, data = {} } = useHorseImages(id)
  const [css, theme] = useStyletron()

  if (isLoading)
    return (
      <Block marginBottom="8px">
        <Skeleton
          height="215px"
          width="100%"
          animation
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                borderTopRightRadius: $theme.sizing.scale100,
                borderTopLeftRadius: $theme.sizing.scale100,
                borderBottomLeftRadius: $theme.sizing.scale100,
                borderBottomRightRadius: $theme.sizing.scale100
              })
            }
          }}
        />
      </Block>
    )

  const { signature = null, width, height } = getPrimaryImage(data?.images)

  if (isNull(signature))
    return (
      <Block marginBottom="8px">
        <Skeleton
          height="215px"
          width="100%"
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                borderTopRightRadius: $theme.sizing.scale100,
                borderTopLeftRadius: $theme.sizing.scale100,
                borderBottomLeftRadius: $theme.sizing.scale100,
                borderBottomRightRadius: $theme.sizing.scale100
              })
            }
          }}
        />
      </Block>
    )

  return (
    <GlobalImage
      src={`https://res.cloudinary.com/asciant/image/upload/f_auto/sell-my-horse/${signature}`}
      width={width}
      height={height}
    />
  )
}

Image.propTypes = {
  id: PropTypes.number.isRequired
}

export default Image
